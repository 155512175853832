/**
 * Global styles
 */

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

:root {
  /* fonts */
  --th-font-logo: Raleway, sans-serif;
  --th-font-main: Raleway, sans-serif;
  --th-font-mono: 'Roboto Mono';
  --th-font-small: Roboto;
  --tx-logo: #d0d2d6;
  /* --th-font-main: 'Courier New', Courier, monospace; // DEBUG test font implementation by using courier to be sure. */

  /* colors */
  /* https://semantic-ui.com/usage/theming.html */
  --green: #548C2F;
  --orange: #CA6702;
  --red: #AE2012;
  --yellow: #f3be0e;
  --blue: #0a6869;
  --grey: #a0a0a0;

  --th-feature1: #0a6869;
  --th-feature1-darker: #074546;

  --bg-black: #090a0a; /* header, footer */
  --bg-dark: #141818; /* main background */
  --bg-dark-card: #23252b;
  --bg-dark-mid: #282B31;
  --bg-light: #d0d2d6;
  --bg-hover: #4e565a;
  --bg-selected: #4e565a;
  --bg-selected-disabled: #222527;

  --bd-dark: #141818;
  --bd-mid: #363941;
  --bd-light: #4e565a;
  --bd-lighter: #6f7a80;

  --tx-black: #000000;
  --tx-light-mid: #464646; 
  --tx-light: #e6e6e6; /* most text light white colour */
  --tx-muted: rgba(230, 230 ,230, 0.65);
  --tx-dim: rgba(230, 230 ,230, 0.45);
  
  --th-ui-actionable: #2f333a; /* buttons, dropdowns, section chooser, currently th lines/borders in page */
  --th-ui-actionable-hover: #282b31;
  --th-positive: #a6cca5;
  --th-positive-darker: #5a7459;
  --th-danger: #d83728;
  --th-danger-darker: #9B2226;
  --th-positive: var(--th-feature1);
  --th-positive-darker: var(--th-feature1-darker);
 

  --ui-selected: #c4c6c9;
  --ui-hover: #a0a4a5;
  --ui-disabled: #303436;



  /* sizes */
  --header-height: 60px;
  --left-sidebar-width: 250px;
  --left-sidebar-compact-width: 80px;
  --right-sidebar-width: 400px;
  --footer-height: 50px;

  --panel-title-width: 110px; /* default */
  --panel-title-width-small: 90px;
  --panel-title-width-medium: 130px;
  --panel-title-width-large: 150px;
  --panel-title-width-xlarge: 220px;
}

/* ------------------------------------------------------ */

/* FIXME improve scrollbars (unify general + timeline, window blur color & should scrollbars reserve space?) */

body {
  background-color: var(--bg-dark);
  color: var(--tx-light);
  font-family: var(--th-font-main);
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none; /* disable scrolling bounce - ref: https://stackoverflow.com/a/65871351 */
}

body ::-webkit-scrollbar-corner {
  background-color: transparent;
}

body ::-webkit-scrollbar-thumb {
  background-color: var(--tx-light-mid);
}

body ::-webkit-scrollbar-thumb:hover {
  background-color: var(--tx-light);
}

body ::-webkit-scrollbar-track {
  background-color: transparent;
}

a {
  color: var(--tx-light);
  transition: opacity 0.3s ease;
}

a:hover {
  opacity: 0.65;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--th-font-main) !important;
  font-weight: 300;
}

/* ------------------------------------------------------ */

#root {
  height: 100%;
}

/* ------------------------------------------------------ */

.dimmer {
  backdrop-filter: blur(15px);
  background-color: rgba(0, 0, 0, 0.65) !important;
}

.dimmer:has(.animating.in) {
  animation: dimmerFadeIn 0.5s;
}

.dimmer:has(.animating.out) {
  animation: dimmerFadeOut 0.5s;
}

@keyframes dimmerFadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes dimmerFadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.ui.modal.active {
  border-radius: 5px;
  background-color: transparent;
}
.ui.modal>.content,
.ui.modal>.actions {
  color: var(--tx-light);
  background-color: var(--bg-dark-mid);
}
.ui.modal>.actions .button {
  min-width: 100px;
}
.ui.modal>.close, .ui.modal>.close:hover {
  background: transparent !important;
  /* border: 1px solid red !important; */
  top: 0px;
  right: 0px;
}


.ui.header.inverted {
  color: var(--tx-light);
  font-family: var(--th-font-main)!important;
  text-transform: none;
  text-shadow: none;
  font-weight: 500;
  line-height: 1em;
  font-style: normal;
  text-decoration: none;
}
.ui.header.medium.inverted {
  color: var(--tx-light);
  font-family: var(--th-font-main)!important;
  text-transform: none;
  text-shadow: none;
  font-weight: 500;
  line-height: 1em;
  font-style: normal;
  text-decoration: none;
}

.ui.list .list>.item .header,
.ui.list>.item .header {
  font-family: var(--th-font-main);
}
.ui.list .content {
  display: flex;
}
.ui.list .content .listIcon {
  margin: 5px;
}


.ui.menu.tabular {
  border-bottom: 1px solid var(--th-ui-actionable);
}
.ui.menu.tabular .item {
  color: var(--tx-light);
  background: transparent;
}
.ui.menu.tabular .item.active {
  color: var(--tx-light);
  background: var(--th-ui-actionable);
  border-color: var(--th-ui-actionable);
}
.ui.menu.tabular .item:hover {
  background: var(--bg-hover);
  color: var(--ui-hover);
  cursor: pointer; 
}


.ui.segment.tab {
  background: transparent;
  border-color: var(--th-ui-actionable);
}

.ui.inverted.segment {
  background-color: var(--bg-dark-card)!important;
}

.ui.label {
  color: var(--tx-light)!important;
  background-color: transparent!important;
}

.ui.icon.input>i.icon:after,
.ui.icon.input>i.icon:before {
  left: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
  margin-top: -.5em;
  color: var(--bg-light);
}


.ui.items .item .content .header,
.ui.items .item .content .meta, 
.ui.items .item .content .description {
  color: var(--tx-light);
  font-family: var(--th-font-main);
}


.ui.message {
  position: relative;
  min-height: 1em;
  margin: 1em 0;
  background: #f8f8f9;
  padding: 6px 10px 5px 10px;
  font-size: 1em;
  line-height: 1.4285em;
  color: var(--tx-light);
  transition: opacity .1s ease,color .1s ease,background .1s ease,box-shadow .1s ease;
  border-radius: .28571429rem;
}
.ui.message.positive,
.ui.message.positive.attached {
  box-shadow: 0 0 0 1px #a3c293 inset, 0 0 0 0 transparent;
}
.ui.message.positive {
  margin: 4px!important;
  background-color: var(--bg-light);
  color: var(--th-positive-darker);
}
.ui.message.positive .content {
  color: var(--th-positive-darker);
}

.ui .message .header, .ui.positive.message .header, .ui.negative.message .header, .ui.compact.message .header, .ui.info.message .header{
  text-transform: capitalize !important;
}

[data-tooltip]:hover:before, [data-tooltip]:hover:after {
  opacity: 1!important;
  border: 4px red!important;
  background-color: var(--bg-light)!important;
  color: var(--tx-black);
  font-weight: 600!important;
  font-family: var(--th-font-main)!important;
}

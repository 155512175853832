.settingsView {
  width: 100%;
  padding-top: 10px;
  display: flex;
  gap: 10px;
}

.settingsError {
  min-width: 360px;
  padding-top: 10px;
}


.propertyTitle {
  width: 220px !important;
}
.propertyValue {}
.propertyValueOff {
  color: var(--orange);
}


.transcoderSettings {
  width: 400px;
}

.transcoderSettings .loading {
  margin: 20px;
}

.transcoderSettings .transcoderHeader {
  padding: 0px;
  text-align: left;
}
.transcoderSettings .transcoderHeader :global(.content) {
  padding: 15px 15px 15px 15px !important;
}


.transcoderSettings .transcoderFooter {
  padding: 10px !important;
}

.transcoderSettings table tbody tr td {
  height: 30px;
}

.transcoderSettings .noAudioOnlyProtocolWarning {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5px;
}
.transcoderSettings .noAudioOnlyProtocolWarning .warningIcon {
  display: block;
}
.transcoderSettings .noAudioOnlyProtocolWarning .warningText {
  margin-left: 8px;
}

.transcoderSettings .transcoderFooter .footerWrapper {
  /* display: flex !important;
  justify-content: space-between;
  align-items: center; */
}
.transcoderSettings .transcoderFooter .buttons {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  align-items: center; 
}
.transcoderSettings .transcoderFooter .saveChanges {
  color: var( --orange);
}

.transcoderSettings .transcoderSummary {
  text-align: center;
}

.transcoderSettings .transcoderSync {}
.transcoderSettings .transcoderSync .transcoderSyncTitle {
  font-weight: bold;
  margin:  0 0 5px 10px;
  color: var(--tx-muted);
}

.transcoderSettings .transcoderSync .transcoderSyncForce {
  margin-top: 10px;
}

.transcoderSettings .transcoderSync .transcoderSyncRequired {
  color: var(--orange);
}
.transcoderSettings .transcoderSync .transcoderSyncRequired .transcoderSyncWarning {
  border: 1px solid var(--orange);
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}
.transcoderSettings .transcoderSync .transcoderSyncRequired button {
  background-color: var(--orange) !important;
}
.transcoderSettings .transcoderSyncSuccess {
  color: var(--green);
  text-align: center;
  margin-bottom: 10px;
  border: 1px solid var(--green);
  border-radius: 10px;
  padding: 10px;
}
.transcoderSettings .transcoderSyncSuccess .title {
  font-weight: bold;
}
.transcoderSettings .transcoderSyncError {
  color: var(--red);
  text-align: center;
  border: 1px solid var(--red);
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 5px;
}
.transcoderSettings .transcoderSyncError .title {
  font-weight: bold;
}

.transcoderSettings .protocolsHeader, .transcoderSettings .hotlinkHeader {
  font-weight: bold;
  font-size: 1.2em;
  padding: 5px 5px 5px 15px;
}
.transcoderSettings .protocolAlwaysEnabled {
  font-size: 0.8em;
  padding-left: 10px;
}
.transcoderSettings .protocolAudioOnly {
  font-size: 0.8em;
  padding-left: 10px;
}
.transcoderSettings .hotlinkNotice {
  padding: 0 15px;
  font-size: 0.95em;
}
.transcoderSettings .hotlinkValidMins {
  min-width: 110px;
}
.transcoderSettings .hotlinkValidMins :global(div.dropdown) {
  /* padding: 8px 12px !important; */
  /* min-height: 1em !important; */
  user-select: none;
}
.transcoderSettings .hotlinkValidMins :global(i.dropdown) {
  /* padding: 8px 10px !important; */
}
.transcoderSettings .hotlinkValidMins :global(.dropdown .text){
  text-transform: none;
}
.transcoderSettings .hotlinkValidMins :global(.menu) {
  min-width: 150px !important; /* expand so the longer default line fits on a single line */
  border-radius: 0 0.3rem 0.3rem 0.3rem !important;
}

.transcoderSettings .transcoderDisabledWarnings {
  border: 1px solid var(--orange);
  color: var(--orange);
  border-radius: 10px;
  padding: 10px;
}
.transcoderSettings .transcoderDisabledWarnings .title {
  font-weight: bold;
  margin-bottom: 5px;
}
.transcoderSettings .transcoderDisabledWarnings .warningMsg:not(:last-child) {
  margin-bottom: 10px;
}


.accessSettings {
  width: 400px;
  margin-top: 10px;
}
.accessSettings .header {
  padding: 0px;
  text-align: left;
}
.accessSettings .header :global(.content) {
  padding: 15px 15px 15px 15px !important;
}


.userSettings {
  width: 400px;
  margin-top: 10px;
}
.userSettings .header {
  padding: 0px;
  text-align: left;
}
.userSettings .header :global(.content) {
  padding: 15px 15px 15px 15px !important;
}
.userSettings .userFooter {
  padding: 10px !important;
}
.userSettings .userFooter .footerWrapper {}
.userSettings .userFooter .buttons {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  align-items: center; 
}
.userSettings .userFooter .saveChanges {
  color: var( --orange);
}
.userSettings .numberInput :global(.ui.input) > input {
  width: 35px;
  padding: 0;
  text-align: center !important;
}
.userSettings .numberInput button:global(.ui.button.mini) {
  padding: 9px 5px;
}


.watermarkSummary {
  width: 400px;
}
.watermarkSummary .header {
  padding: 0px;
  text-align: left;
}
.watermarkSummary .header :global(.content) {
  padding: 15px 15px 15px 15px !important;
}
/* .watermarkSummary img {
  display: block;
  max-width: 300px;
  margin: auto;
} */
.watermarkSummary .screenPreview {
  margin: 0 auto 8px auto;
}
.watermarkSummary .noWatermark {
  text-align: center;
  padding: 10px;
}

.watermarkSummary .noWatermark:first-letter {
 text-transform: capitalize;
}

.watermarkSummary .watermarkingDisabled {
  text-align: center;
  padding: 10px;
}
.watermarkSummary .editLink {
  text-align: center;
  padding: 5px;
  font-weight: bold;
}
.watermarkSummary .transcoderDisabledWarning {
  /* border: 1px solid var(--orange); */
  color: var(--orange);
  border-radius: 10px;
  padding: 5px;
  text-align: center;
}

/**
 * common
 */

.panel {
  width: 400px;
}

.panelHeader {
  padding: 15px;
}

.panelFooter {
  padding: 10px;
}

.panelFooterButtons {
  display: flex;
  justify-content: space-between;
}

.panelFooterText {
  color: var(--tx-muted);
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}

.panelFooterTextDanger {
  color: var(--th-danger);
}

/* FIXME improve ArkNumberInput */
.numberInput input {
  text-align: center !important;
  width: 118px;
}

/**
 * time zone
 */

 .timeZoneLocalContainer {
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: space-between;
  padding-left: 14px;
  width: 100%;
  /* background-color: rgba(255, 0, 0, 0.25); */
 }
